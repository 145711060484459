import MainCard from "../components/card/MainCard";
import SecondaryCard from "../components/card/SecondaryCard";
import Sidebar from "../layout/sidebar/Sidebar";
import React, { useEffect, useState } from 'react';

export default function Home() {
  const url1 = 'https://script.google.com/macros/s/AKfycbzbczs9xv5CPSipP-6NWB1IEpGq2xG8pCbNw0X8-tZwD9Y_rN8qr4LAWCRIG-eXlyFx/exec';
  const url2 = 'https://script.google.com/macros/s/AKfycbyPxnfVp06QqCHoC5rmqD-eNdfstVmKkr54iDapJbwqacdVdhGsK7CaQqTW8FFf_Bq4oA/exec';
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);

  const fetchData = async (url, setDataFunction) => {
    try {
      const response = await fetch(url);
      const jsonData = await response.json();
      jsonData.sort((a, b) => {
        return new Date(b.Data) - new Date(a.Data);
      });
      setDataFunction(jsonData);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  useEffect(() => {
    fetchData(url1, setData1);
    fetchData(url2, setData2);
  }, []);

  return (
    <section className="flex h-[100%]"> 
      <Sidebar currentAppPage={"/home"}/> 
      <div className="flex flex-col w-full mr-16"> 
        <MainCard
          hasHeader={true}
          title={"Novidades!"}
          element={
            data2 ? (
              <SecondaryCard
                hasTitle={true}
                hasListTitle={false}
                dataList={data2} />
            ) : (
              <p className="ml-100 mt-10 mb-5 text-[18px] text-[#545353] font-semibold">
                Carregando dados...
              </p>
            )
          }
          secondElement={
            data1 ? (
              <SecondaryCard hasListTitle={true} dataList={data1} />
            ) : (
              <p className="ml-100 mt-10 mb-5 text-[18px] text-[#545353] font-semibold">
                Carregando dados...
              </p>
            )
          }
        />
      </div>
    </section>
  );
}